<template>
  <div class="evaluation mt-5 cart-Page cart-Page-adrreess">
    <div class="topboxorder">
      <div class="current-orders py-4">
        <div class="icons d-flex justify-content-center">
          <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
          <b-icon icon="dash" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash" class="orange circle-icon"></b-icon>
          <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
          <b-icon icon="dash" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash" class="orange circle-icon"></b-icon>
          <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
          <b-icon icon="dash" class="pink circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
          <b-icon icon="dash" class="pink circle-icon"></b-icon>
          <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
        </div>
        <div class="order-done d-flex justify-content-center pt-3">
          <span class="mx-5"> {{ $t("shopping cart") }} </span>
          <span class="mx-5"> {{ $t("shipping address") }} </span>
          <span class="mx-5"> {{ $t("payment") }} </span>
          <span class="mx-5">{{ $t("Submit a request") }} </span>
        </div>
      </div>
    </div>

    <div class="container items">
      <h5 class="d-inline-block ms-2 mb-3">{{ $t("map") }}</h5>
      <div class="shopping-cart d-flex w-100">
        <div class="paying p-3">
          <div class="from-all">
            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-info" @click="geolocate">
                  {{ $t("Detect My Location") }}
                </button>
                <p>{{ $t("Selected Position") }}: {{ marker.position }}</p>
              </div>
              <div class="col-md-6 text-left">
                <button class="btn-addaddress btn mb-3" @click="save">
                  {{ $t("save") }}
                </button>
              </div>
            </div>

            <GmapMap
              :center="center"
              :zoom="13"
              map-style-id="roadmap"
              style="width: 100%; height: 600px"
              ref="mapRef"
              @click="handleMapClick"
            >
              <GmapMarker
                :position="marker.position"
                :clickable="true"
                :draggable="true"
                @drag="handleMarkerDrag"
                @click="panToMarker"
              />
            </GmapMap>
          </div>
        </div>

        <div class="discount-box">
          <div class="discount">
            <div class="gifts">
              <p>{{ $t("Discount coupons and gift cards") }}</p>
              <div class="btns d-flex justify-content-between">
                <input
                  type="text"
                  class="sign1"
                  :value="cart.discountId.name"
                  v-if="cart.discountId"
                  disabled
                />
                <input
                  v-else
                  type="text"
                  class="sign1"
                  v-model="discountCode"
                  :placeholder="$t('Enter the coupon or card code')"
                  @keyup.enter="checkDescount()"
                />
                <button
                  class="sign2"
                  @click="deleteDiscount()"
                  v-if="cart.discountId"
                >
                  {{ $t("cncel") }}
                </button>
                <button class="sign2" @click="checkDescount()" v-else>
                  {{ $t("use") }}
                </button>
              </div>
            </div>

            <div class="orders-summary">
              <h5>
                {{ $t("Order summary") }}
              </h5>
              <div class="date d-flex justify-content-between mb-2">
                <p>
                  {{ $t("Subtotal") }}
                </p>
                <span class="price">{{ Math.round(cart.subTotal) }} {{ $t("SAR") }}</span>
              </div>
              <div
                class="date d-flex justify-content-between mb-2"
                v-if="cart.discountValue > 0"
              >
                <p>{{ $t("Discount") }}</p>
                <span class="price"
                  >-{{ Math.round(cart.discountValue) }} {{ $t("SAR") }}</span
                >
              </div>
              <div class="date d-flex justify-content-between mb-2">
                <p>{{ $t("Total") }}</p>
                <span class="price">{{ Math.round(cart.total) }} {{ $t("SAR") }}</span>
              </div>
            </div>

            <div class="delivery-costs mt-4">
              <h6>
                {{ $t("Delivery costs") }}
              </h6>
              <p>
                {{
                  $t("It will be calculated after adding the delivery address")
                }}
              </p>
              <!-- <div class="plus-btn2"> -->
              <div class="text-center">
                <button class="border-0 btn-done-cart" @click="done()">
                  {{ $t("Complete your purchase") }}
                </button>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cart: {
        subTotal: 0,
        total: 0,
        cartItemsList: []
      },
      body: {},
      discountCode: null,
      list: [],
      marker: { position: { lat: 21.4925, lng: 39.17757 } },
      center: { lat: 21.4925, lng: 39.17757 }
    };
  },
  methods: {
    save() {
      sessionStorage.lat = this.marker.position.lat;
      sessionStorage.lng = this.marker.position.lng;

      this.$router.go(-1);
    },
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.panToMarker();
      });
    }, //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    }, //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(13);
    }, //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    checkDescount() {
      this.$http
        .post("discounts/checkdiscountCode", {
          discountCode: this.discountCode
        })
        .then(
          (res) => {
            this.getCarts();
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("Error"),
              detail: err.response.data.message,
              life: 3000
            });
          }
        );
    },
    deleteDiscount() {
      this.$http.post("cart/deleteDiscount", {}).then((res) => {
        this.getCarts();
      });
    },
    getCarts() {
      this.$http.get("cart").then(
        (res) => {
          if (res.data.returnStatus) {
            this.cart = res.data.data;
          } else {
            this.$router.push("/");
          }
        },
        (err) => {
          this.$router.push("/");
          this.$toast.add({
            severity: "error",
            summary: this.$t("Error"),
            detail: err.response.data.message,
            life: 3000
          });
        }
      );
    }
  },
  created() {
    this.getCarts();
  },
  mounted() {
    this.geolocate();
  }
};
</script>

<style></style>
